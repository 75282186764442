import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
import bus from "@/plugins/bus";
export default {
  name: "permissionAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        permissionId: null,
        permissionCode: null,
        permissionName: null,
        type: '菜单',
        icon: null,
        parentId: null,
        permissionIndex: 1
      },
      formRules: {
        permissionCode: [{
          required: true,
          message: '请输入权限编码',
          trigger: 'blur'
        }, {
          max: 32,
          message: '权限编码长度不能超过32',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z_/.]+$/,
          message: '权限编码必须为字母或_',
          trigger: 'blur'
        }],
        permissionName: [{
          required: true,
          message: '请输入权限名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '权限名称长度不能超过32',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择权限类型',
          trigger: 'blur'
        }],
        permissionIndex: [{
          required: true,
          message: '请选择权限类型',
          trigger: 'blur'
        }]
      },
      permissions: [],
      permissionProps: {
        checkStrictly: true,
        emitPath: false,
        value: 'permissionId',
        label: 'permissionName'
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.uac.permission.update(this.formModel).then(callBack);
          } else {
            this.$api.uac.permission.create(this.formModel).then(callBack);
          }
        }
      });
    },
    // 过滤掉按钮，按钮不能作为上级菜单
    filterButton(permissions) {
      permissions.forEach(permission => {
        if (permission.children && permission.children.length > 0) {
          permission.children = permission.children.filter(v => v.type === '菜单');
          this.filterButton(permission.children);
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.permissionId) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.uac.permission.detail(this.$route.query.permissionId).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 101302) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
    this.$api.uac.permission.list().then(res => {
      if (res.code === 200) {
        // 过滤按钮
        this.filterButton(res.data);
        this.permissions = res.data;
      }
    });
  }
};